import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../user-context";
import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import ConsultationSummary from "../../../SharedComponents/ConsultationSummary";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ActionButton } from "../../../SharedComponents/Buttons";
import { addDoc, getDoc, updateDoc, query, getDocs } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../App";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";

import { getFirestore, doc, onSnapshot, collection } from "firebase/firestore";

function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}

const options = {
  weekday: "long",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};
const timeOptions = {
  hour: "2-digit",
  minute: "2-digit",
};

const Success = (props) => {
  const location = useLocation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [appointment, setAppointment] = useState();
  const [date, setDate] = useState(new Date());
  const [pro, setPro] = useState();
  const [user, setUser] = useState();
  const [type, setType] = useState();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const db = getFirestore();
  function convertToProTZ(date, tzString) {
    return new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
        "en-US",
        { timeZone: tzString }
      )
    );
  }

  const sendProConfirmationEmail = async (appointmentDetails) => {
    await addDoc(collection(db, "mail"), {
      to: [appointmentDetails.pro.email],
      message: {
        subject: "Matriarchy Build Booking Confirmed!",
        html: `  <div class="entry" style="text-color:grey";>
       
        <div class="body">
        <tbody><tr>
        <td id="m_2221956471352103756m_5517837448314080125header_wrapper" style="background-color:#F7CBAD;padding:36px 48px;display:block;text-align:center">
          <a href="http:///" style="color:#2d457c;font-weight:normal;text-decoration:underline" target="_blank" data-saferedirecturl="https://matriarchybuild.com"><img src="https://firebasestorage.googleapis.com/v0/b/mb-platform.appspot.com/o/misc%2Femail-header.png?alt=media&token=a345823f-7569-483f-85e7-f5cde48d7211" style="border:none;display:inline-block;font-size:14px;font-weight:bold;height:auto;outline:none;text-decoration:none;text-transform:capitalize;vertical-align:middle;margin-right:10px;max-width:100%;margin:0" class="CToWUd" data-bit="iit"></a>
        </td>
      </tr>
    </tbody>
    <h2 style="margin-bottom:20px;">Hi, ${appointmentDetails.pro.firstName}</h2>
          <p style="margin-bottom:20px;">
          ${appointmentDetails.userData.firstName} has booked a consultation
          </p>
          <p style="margin-bottom:20px;  font-weight: bold;">
            Consultation Confirmation
          </p>
         
          <p style="margin-bottom:20px; text-decoration:underline">
            The Consultation
          </p>
          <p>${appointmentDetails.type?.label + " Consultation"}</>
          <p>${new Date(appointmentDetails.startTime).toLocaleDateString(
            "en-us",
            { ...options, timeZone: appointmentDetails.pro?.timeZone }
          )}</p>
          <p style="margin-bottom:20px">
            You can access your ${
              appointmentDetails.type?.label + " Consultation"
            }
            ${` - ${appointmentDetails.type.duration} min`} with ${
          appointmentDetails.pro?.firstName
        }
            through your personal 
            <a href=https://matriarchybuild.com/video-consultation/${
              appointmentDetails.id
            }>Video Link</a>
          </p>
          <p style="margin-bottom:20px; text-decoration:underline">
            Notes for your session
          </p>
          <p style="margin-bottom:20px">
            Click  <a href=https://matriarchybuild.com/my-account/consultation-details/${
              appointmentDetails.id
            }>here</a> to view your
            pre-consultation questionnaire.
          </p>
          
        </div>
      </div>`,
      },
    });
  };
  const sendConfirmationEmail = async (appointmentDetails) => {
    await addDoc(collection(db, "mail"), {
      to: [appointmentDetails.user.email],
      message: {
        subject: "Matriarchy Build Appointment Confirmed!",
        html: `  <div class="entry" style="text-color:grey";>
       
        <div class="body">
        <tbody><tr>
        <td id="m_2221956471352103756m_5517837448314080125header_wrapper" style="background-color:#F7CBAD;padding:36px 48px;display:block;text-align:center">
          <a href="http:///" style="color:#2d457c;font-weight:normal;text-decoration:underline" target="_blank" data-saferedirecturl="https://matriarchybuild.com"><img src="https://firebasestorage.googleapis.com/v0/b/mb-platform.appspot.com/o/misc%2Femail-header.png?alt=media&token=a345823f-7569-483f-85e7-f5cde48d7211" style="border:none;display:inline-block;font-size:14px;font-weight:bold;height:auto;outline:none;text-decoration:none;text-transform:capitalize;vertical-align:middle;margin-right:10px;max-width:100%;margin:0" class="CToWUd" data-bit="iit"></a>
        </td>
      </tr>
    </tbody>
    <h2 style="margin-bottom:20px;">Hi,</h2>
          <p style="margin-bottom:20px;">
            You’re one step closer to tackling your next project.
          </p>
          <p style="margin-bottom:20px;  font-weight: bold;">
            Consultation Confirmation
          </p>
         
          <p style="margin-bottom:20px; text-decoration:underline">
            Your Consultation
          </p>
          <p>${appointmentDetails.type?.label + " Consultation"}</>
          <p>${new Date(appointmentDetails.startTime).toLocaleDateString(
            "en-us",
            options
          )}</p>
          <p style="margin-bottom:20px">
            You can access your ${
              appointmentDetails.type?.label + " Consultation"
            }
            ${` - ${appointmentDetails.type.duration} min`} with ${
          appointmentDetails.pro?.firstName
        }
            through your personal 
            <a href=https://matriarchybuild.com/video-consultation/${
              appointmentDetails.id
            }>Video Link</a>
          </p>
          <p style="margin-bottom:20px; text-decoration:underline">
            Prepare for your session
          </p>
          <p style="margin-bottom:20px">
            We strongly recommend completing your pre-consultation questionnaire
            and submitting all relevant photos, pdfs, videos and links to your
            Pro prior to your session with ample time for your Pro to review
            them. Depending on your project type, measurements, tools, drawings
            or images might be useful. Click  <a href=https://matriarchybuild.com/my-account/consultation-details/${
              appointmentDetails.id
            }>here</a> to fill out your
            pre-consultation questionnaire.
          </p>
          <p>
            And visit our <a href=https://matriarchybuild.com/how-it-works/>How it Works </a>section for more guidance on preparing
            for your session. 
          </p>
        </div>
      </div>`,
      },
    });
  };

  const createAppointment = async (appointmentDetails) => {
    setLoading(true);

    const endTime = addMinutes(
      new Date(appointmentDetails.startTime),
      appointmentDetails.type.duration
    );

    const addEvent = httpsCallable(functions, "addEventToCalendar");

    const startTime = new Date(appointmentDetails.startTime);

    const result = await addEvent({
      calendarId: appointmentDetails.pro.calendarId,
      conferenceDataVersion: 1,
      join_url: `https://matriarchybuild.com/video-consultation/${appointmentDetails.id}`,
      attendees: [
        {
          email: appointmentDetails.pro.calendarId,
        },
        {
          displayName: appointmentDetails.pro.firstName,
          email: appointmentDetails.pro.email,
        },
        {
          displayName: firstName,
          email: appointmentDetails.userData.email,
        },
      ],
      eventName: `${appointmentDetails.pro.firstName} ${appointmentDetails.pro.lastName} - 1:1 Consultation`,
      description: appointmentDetails.type.description,
      startTime: startTime,
      endTime: endTime,
    }).catch((error) => {
      setLoading(false);
      return error;
    });

    setLoading(false);
    return result;
  };

  const getAppointmentInfo = async () => {
    const docRef = doc(db, `appointments/${id}`);
    const docSnap = await getDoc(docRef);

    const appointment = docSnap.data();
    const appointmentId = docSnap.id;
    setUser(appointment.user);
    setType(appointment.type);

    const userRef = doc(db, `customers/${appointment.userId}/`);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists) {
      const userData = userSnap.data();

      setAppointment(appointment);
      setDate(new Date(appointment.startTime));

      const proDocRef = doc(db, `pros/${appointment.proId}`);
      const proDocSnap = await getDoc(proDocRef);

      setPro(proDocSnap.data());

      if (appointment.calendarEventCreated != true) {
        const result = await createAppointment({
          userData: userData,
          ...appointment,
          pro: proDocSnap.data(),
          id: appointmentId,
        });

        appointment.calendarEventCreated = true;
        appointment.calendarInfo = result.data.data;
        appointment.confirmed = true;
        appointment.createdAt = new Date();

        updateDoc(docRef, appointment);

        appointment.userData = userData;
        appointment.pro = proDocSnap.data();
        appointment.id = appointmentId;

        sendConfirmationEmail(appointment);
        sendProConfirmationEmail(appointment);

        if (appointment.giftCard) {
          const giftCardRef = doc(db, `giftCards`, appointment.giftCard.id);
          await updateDoc(giftCardRef, {
            cardDetails: { ...appointment.giftCard.cardDetails },
          });
        } else if (appointment.promoCode) {
          const promoCodeRef = doc(db, `promoCodes`, appointment.promoCode.id);
          const timesUsed = appointment.promoCode.timesUsed + 1;
          await updateDoc(promoCodeRef, {
            timesUsed: timesUsed,
          });
        }
      }
    }
  };

  useEffect(() => {
    getAppointmentInfo();
  }, []);

  const handleClose = () => {};
  return (
    <>
      <Dialog onClose={handleClose} open={loading}>
        <Box
          sx={{
            p: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress mx="auto" />
        </Box>
        <Box sx={{ mx: "44px", mb: "24px" }}>
          <Typography
            sx={{
              whiteSpace: "pre-line",
              color: "blue.main",
              mr: "5px",
              mb: "8px",
            }}
            variant="paragraphMono"
          >
            {"Preparing your order"}
          </Typography>
        </Box>
      </Dialog>

      <Box
        width="100%"
        height="100vh"
        sx={{
          backgroundColor: "cream.main",
          px: "44px",
        }}
      >
        <Box width="100%" maxWidth="1088px" mx="auto">
          <Box sx={{ borderBottom: 1, color: "lightOlive.main" }}>
            <Typography
              sx={{
                whiteSpace: "pre-line",
                color: "olive.main",
                mr: "5px",
                pt: "87px",
                mb: "8px",
              }}
              variant="h2"
            >
              {"Booking confirmed"}
            </Typography>
          </Box>
          {pro && appointment && date && (
            <ConsultationSummary
              id={id}
              presentedAt="confirmation"
              date={date}
              pro={pro}
              appointmentDetails={appointment}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default Success;
