import { Box } from "@mui/material";
import { useState, useContext, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "react-responsive";
import SideBar from "../../SideBar";
import { useLocation } from "react-router-dom";
import ConsultationSummary from "../../../../SharedComponents/ConsultationSummary";
import Footer from "../../../../SharedComponents/Footer";
import { UserContext } from "../../../../user-context";
import Input from "@mui/material/Input";
import Grid from "@mui/material/Unstable_Grid2/";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import Backdrop from "@mui/material/Backdrop";
import SendIcon from "@mui/icons-material/Send";
import {
  getFirestore,
  query,
  getDocs,
  addDoc,
  collection,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  where,
} from "firebase/firestore";
import { uploadBytes, getDownloadURL, ref } from "firebase/storage";
import { ActionButton } from "../../../../SharedComponents/Buttons";
import DropzoneComponent from "../../../../SharedComponents/Dropzone";
import { storage } from "../../../../App";
import { v4 as uuidv4 } from "uuid";
import ImageViewer from "react-simple-image-viewer";
import Close from "@mui/icons-material/Close";
const commonStyles = {
  pl: "4px",
  pt: "4px",
  mb: "12px",
  fontFamily: "Code Saver",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "16px",
  lineHeight: "145%",
  color: "blue.main",
  border: 1,
  borderColor: "lightOlive.main",
};

const options = {
  weekday: "long",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

const ConsultationDetails = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const isMedium = useMediaQuery({ minWidth: 901 });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const [note, setNote] = useState("");
  const [proNote, setProNote] = useState("");
  const [question1, setQuestion1] = useState("");
  const [question2, setQuestion2] = useState("");
  const [question3, setQuestion3] = useState("");
  const [question4, setQuestion4] = useState("");
  const [question5, setQuestion5] = useState("");
  const [question6, setQuestion6] = useState("");

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [appointment, setAppointment] = useState();
  const [appointmentImages, setAppointmentImages] = useState([]);
  const [editingNote, setEditingNote] = useState(false);
  const [editingProNote, setEditingProNote] = useState(false);
  const [uploadingPhotos, setUploadingPhotos] = useState(false);
  const [pro, setPro] = useState();
  const [saved, setSaved] = useState(false);
  const [saving, setSaving] = useState(false);
  const [uploading, setUploading] = useState(false);

  const location = useLocation();

  const { state } = location;

  const db = getFirestore();

  const [userType, setUserType] = useState("user");
  const [loading, setLoading] = useState("false");

  const user = useContext(UserContext);

  const setUserAttributes = async (id) => {
    if (user.loggedIn == true) {
      const proRef = doc(db, `pros/${id}/`);
      const docSnap = await getDoc(proRef);

      if (docSnap.exists()) {
          const proData = docSnap.data();
        setPro (docSnap.data())
        setUserType("pro");
       
        //handleProSelect({ id: docSnap.id, ...proData });
      } else {
        user.user
          .getIdTokenResult()
          .then((idTokenResult) => {
            setUserType(idTokenResult.claims.userType);
            if (idTokenResult.claims.userType === "admin") {
              console.log ("admin")
            } else {
              setUserType("user");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  useEffect(() => {
    if (user.loggedIn) {
      //setUserAttributes(user.user.uid);
    }
  }, [user]);

  const getAppointments = async () => {};

  useEffect(() => {
    getAppointments();
  }, [user, props]);

  const { id } = useParams();

  var mode = "edit";

  if (id == undefined) {
    mode = "create";
  }

  const getAppointment = async () => {
    setLoading(true);
    const docRef = doc(db, "appointments", id);
    const docSnap = await getDoc(docRef);
    setAppointment({ id: id, ...docSnap.data() });

    const projectDetails = docSnap.data().projectDetails;

    setNote(projectDetails.clientNote);
    setProNote(projectDetails.proNote);
    setQuestion1(projectDetails.question1);
    setQuestion2(projectDetails.question2);
    setQuestion3(projectDetails.question3);
    setQuestion4(projectDetails.question4);
    setQuestion5(projectDetails.question5);
    setQuestion6(projectDetails.question6);

    const appointmentImagesRef = collection(db, `appointments/${id}/images`);
    const q = query(appointmentImagesRef);
    const querySnapshot = await getDocs(q);
    const imageURLs = [];

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      imageURLs.push(doc.data().imageURL);
    });

    setAppointmentImages(imageURLs);
    setLoading(false);
  };

  useEffect(() => {
    getAppointment();
  }, [id]);

  const onDrop = useCallback((acceptedFiles) => {
    handlePhotoUpload(acceptedFiles);
  }, []);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handlePhotoUpload = (files) => {
    if (files.length == 0) return;

    setUploading(true);
    files.forEach((file) => {
      const photoId = uuidv4();
      const path = `appointmentImages/${photoId}`;
      const storageRef = ref(storage, path);
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(ref(storage, path))
          .then(function (url) {
            const collectionRef = collection(db, `appointments/${id}/images`);

            const docSnap = addDoc(collectionRef, { imageURL: url })
              .then((result) => {
                appointmentImages.push(url);
                setAppointmentImages(appointmentImages);
                setUploadingPhotos(false);
                setUploading(false);
                getAppointment();
              })
              .catch((error) => {
                console.log(error);
                setUploadingPhotos(false);
                setUploading(false);
              });
          })
          .catch(function (error) {
            console.log(error);
            setUploadingPhotos(false);
            setUploading(false);
          });
      });
    });
  };

  const deletePhoto = async (url) => {
    var filtered = appointmentImages.filter(function (el) {
      return el != url;
    });
    setAppointmentImages(filtered);
    const q = query(
      collection(db, `appointments/${id}/images`),
      where("imageURL", "==", url)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      deleteDoc(doc.ref);
    });

    getAppointment();
  };

  const sendEmailToUser = async (projectDetails) => {
    await addDoc(collection(db, "mail"), {
      to: [appointment.user.email],
      message: {
        subject: "Your Pro Shared a Note",
        html: `  <div class="entry" style="text-color:grey";>
       
        <div class="body">
        <tbody>
           <tr>
              <td id="m_2221956471352103756m_5517837448314080125header_wrapper" style="background-color:#F7CBAD;padding:36px 48px;display:block;text-align:center">
                 <a href="http:///" style="color:#2d457c;font-weight:normal;text-decoration:underline" target="_blank" data-saferedirecturl="https://matriarchybuild.com"><img src="https://firebasestorage.googleapis.com/v0/b/mb-platform.appspot.com/o/misc%2Femail-header.png?alt=media&token=a345823f-7569-483f-85e7-f5cde48d7211" style="border:none;display:inline-block;font-size:14px;font-weight:bold;height:auto;outline:none;text-decoration:none;text-transform:capitalize;vertical-align:middle;margin-right:10px;max-width:100%;margin:0" class="CToWUd" data-bit="iit"></a>
              </td>
           </tr>
        </tbody>
        <h2 style="margin-bottom:20px;">${appointment.pro.firstName}'s Note:</h2>
        <p style="margin-bottom:20px; ">
           ${projectDetails.proNote && projectDetails.proNote}
        </p>
        <p style="margin-bottom:20px; text-decoration:underline">
           Consultation Details
        </p>
        <p>${appointment.type?.label + " Consultation"}</>
        <p>${new Date(appointment.startTime).toLocaleDateString("en-us", {
          ...options,
          timeZone: appointment.pro?.timeZone,
        })}
        </p>
       
        <p style="margin-bottom:20px">
           Click  <a href=https://matriarchybuild.com/my-account/consultation-details/${
             appointment.id
           }>here</a> to view your notes
        </p>
      
        
     </div>
   `},
  });

  };
  const sendEmailToPro = async (projectDetails) => {
    await addDoc(collection(db, "mail"), {
      to: [pro.email],
      message: {
        subject: "A client has added notes to your consultation",
        html: `  <div class="entry" style="text-color:grey";>
       
        <div class="body">
        <tbody>
           <tr>
              <td id="m_2221956471352103756m_5517837448314080125header_wrapper" style="background-color:#F7CBAD;padding:36px 48px;display:block;text-align:center">
                 <a href="http:///" style="color:#2d457c;font-weight:normal;text-decoration:underline" target="_blank" data-saferedirecturl="https://matriarchybuild.com"><img src="https://firebasestorage.googleapis.com/v0/b/mb-platform.appspot.com/o/misc%2Femail-header.png?alt=media&token=a345823f-7569-483f-85e7-f5cde48d7211" style="border:none;display:inline-block;font-size:14px;font-weight:bold;height:auto;outline:none;text-decoration:none;text-transform:capitalize;vertical-align:middle;margin-right:10px;max-width:100%;margin:0" class="CToWUd" data-bit="iit"></a>
              </td>
           </tr>
        </tbody>
        <h2 style="margin-bottom:20px;">Hi, ${appointment.pro.firstName}</h2>
        <p style="margin-bottom:20px; text-decoration:underline">
           The Consultation
        </p>
        <p>${appointment.type?.label + " Consultation"}</>
        <p>${new Date(appointment.startTime).toLocaleDateString("en-us", {
          ...options,
          timeZone: appointment.pro?.timeZone,
        })}
        </p>
        <p style="margin-bottom:20px">
           You can access your ${appointment.type?.label + " Consultation"}
           ${` - ${appointment.type.duration} min`} with ${
          appointment.pro?.firstName
        }
           through your personal 
           <a href=https://matriarchybuild.com/video-consultation/${
             appointment.id
           }>Video Link</a>
        </p>
        <p style="margin-bottom:20px">
           Click  <a href=https://matriarchybuild.com/my-account/consultation-details/${
             appointment.id
           }>here</a> to view the questionaire, view images, and leave a note to the client
        </p>
        <p style="margin-bottom:10px; text-decoration:underline">
           Notes for your session
        </p>
        <p style="margin-bottom:20px; ">
           ${projectDetails.clientNote && projectDetails.clientNote}
        </p>
        <p style="margin-bottom:10px; text-decoration:underline">
           What project or topic are you looking to discuss?
        </p>
        <p style="margin-bottom:20px; ">
           ${projectDetails.question1 && projectDetails.question1}
        </p>
        <p style="margin-bottom:10px; text-decoration:underline">
           What are your goals for your session?
        </p>
        <p style="margin-bottom:20px; ">
           ${projectDetails.question2 && projectDetails.question2}
        </p>
        <p style="margin-bottom:10px; text-decoration:underline">
           Are there any tools, parts, materials, or obstacles specific to your project that you want to share with your Pro in advance of the session? If so, please do so here.
        </p>
        <p style="margin-bottom:20px;">
           ${projectDetails.question3 && projectDetails.question3}
        </p>
        <p style="margin-bottom:10px; text-decoration:underline">
           On a scale of 1 to 5 how would you rate your experience level? (For example, 1: I want to hang shelves but I’ve never used a drill; 5: I want to do a full kitchen remodel and I’ve just completed a bathroom remodel.)
        </p>
        <p style="margin-bottom:20px; ">
           ${projectDetails.question4 && projectDetails.question4}
        </p>
        <p style="margin-bottom:10px; text-decoration:underline">
           Do you have any questions you’d like your Pro to be prepared to address in your session?
        </p>
        <p style="margin-bottom:20px; ">
           ${projectDetails.question5 && projectDetails.question5}
        </p>
        <p style="margin-bottom:10px; text-decoration:underline">
           Are there any additional notes for your Pro? To share large files please list any relevant links for your Pro (Example: Dropbox, Google Doc, Pinterest Board.)
        </p>
        <p style="margin-bottom:20px;">
           ${projectDetails.question6 && projectDetails.question6}
        </p>
     </div>
   `,
      },
    });
  };

  const updateDetails = async () => {
    const docRef = doc(db, "appointments", id);

    var projectDetails = {};

    if (note != undefined) {
      projectDetails.clientNote = note;
    }
    if (proNote) {
      projectDetails.proNote = proNote;
    }
    if (question1) {
      projectDetails.question1 = question1;
    }

    if (question2) {
      projectDetails.question2 = question2;
    }
    if (question3) {
      projectDetails.question3 = question3;
    }
    if (question4) {
      projectDetails.question4 = question4;
    }
    if (question5) {
      projectDetails.question5 = question5;
    }
    if (question6) {
      projectDetails.question6 = question6;
    }

    const values = { projectDetails: projectDetails };
    setSaving(true);

    
    if (userType === "user") {
      if (new Date() < new Date(appointment.startTime)) {
        sendEmailToPro(projectDetails);
      }
    } else if (userType === "pro" || userType === "admin") {
      if (projectDetails.proNote) {
        sendEmailToUser(projectDetails);
      }
    
    }

    await updateDoc(docRef, values);

    setSaved(true);
    setSaving(false);
  };

  return (
    <Box width="100%" minHeight="766px">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || uploading}
      >
        <CircularProgress sx={{ color: "blue.main" }} />
      </Backdrop>
      {isViewerOpen && (
        <ImageViewer
          src={appointmentImages}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
      <Stack width="100%" direction={isDesktop ? "row" : undefined}>
        <Box width={isDesktop ? "345px" : "100%"}>
          <SideBar type={props.type} />
        </Box>

        <Grid container width="100%" sx={{ px: isPortrait ? "20px" : "44px" }}>
          <Grid
            item
            xs={8}
            sx={{ borderBottom: 1, mt: "66px", color: "lightOlive.main" }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "olive.main",
                  mr: "5px",
                  pt: "10px",
                }}
                variant="h3"
              >
                {userType === "user"
                  ? "Your note to the pro"
                  : "A note from the client"}
              </Typography>
              {userType === "user" && (
                <IconButton
                  onClick={() => {
                    if (editingNote) {
                      updateDetails();
                    }
                    setEditingNote(!editingNote);
                  }}
                >
                  {editingNote ? (
                    <FileDownloadDoneIcon />
                  ) : (
                    <EditIcon sx={{ color: "olive.main" }} />
                  )}
                </IconButton>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={8} sx={{ mt: "15px" }}>
            {editingNote ? (
              <Input
                type="text"
                name="note"
                multiline
                rows={5}
                onChange={(e) => setNote(e.target.value)}
                value={note}
                disableUnderline
                fullWidth
                placeholder=""
                sx={{
                  ...commonStyles,
                }}
              ></Input>
            ) : (
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "blue.main",
                  mr: "5px",
                }}
                variant="paragraphMono"
              >
                {note}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={8}
            sx={{ mt: "65px", borderBottom: 1, color: "lightOlive.main" }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "olive.main",
                  mr: "5px",
                  pt: "10px",
                }}
                variant="h3"
              >
                {userType === "user"
                  ? "A note from the pro"
                  : "Your note to the client"}
              </Typography>
              {(userType === "pro" ||
                userType === "admin") && (
                  <IconButton
                    onClick={() => {
                      if (editingProNote) {
                        updateDetails();
                      }
                      setEditingProNote(!editingProNote);
                    }}
                  >
                    {editingProNote ? (
                      <SendIcon />
                    ) : (
                      <EditIcon sx={{ color: "olive.main" }} />
                    )}
                  </IconButton>
                )}
            </Stack>
          </Grid>
          <Grid item xs={8} sx={{ pt: "15px" }}>
            {editingProNote ? (
              <Input
                type="text"
                name="proNote"
                multiline
                rows={5}
                onChange={(e) => setProNote(e.target.value)}
                value={proNote}
                disableUnderline
                fullWidth
                placeholder=""
                sx={{
                  ...commonStyles,
                }}
              ></Input>
            ) : (
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "blue.main",
                  mr: "5px",
                }}
                variant="paragraphMono"
              >
                {proNote}
              </Typography>
            )}
          </Grid>
          <Grid item xs={8} sx={{ pt: "15px" }}>
            <Typography
              sx={{
                whiteSpace: "pre-line",
                color: "blue.main",
                mr: "5px",
              }}
              variant="paragraphMono"
            ></Typography>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{ mt: "45px", borderBottom: 1, color: "lightOlive.main" }}
          >
            <Typography
              sx={{
                whiteSpace: "pre-line",
                color: "olive.main",
                mr: "5px",
              }}
              variant="h3"
            >
              Project Details
            </Typography>
          </Grid>
          <Grid item xs={8} sx={{ mt: "15px" }}>
            <Typography
              sx={{
                whiteSpace: "pre-line",
                color: "blue.main",
              }}
              variant="paragraphMono"
            >
              Add some details to help your Pro get acquainted with your project
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              mt: "25px",

              backgroundColor: "extraLightOlive.main",
            }}
          >
            {uploadingPhotos ? (
              <Grid container>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <IconButton
                    onClick={(event) => setUploadingPhotos(false)}
                    sx={{
                      borderRadius: "0",
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <Typography
                      sx={{
                        whiteSpace: "pre-line",
                        mt: "6px",

                        color: "blue.main",
                      }}
                      display="block"
                      variant="paragraphSans"
                    >
                      Cancel
                    </Typography>

                    <Close sx={{ color: "blue.main", mt: "5px", ml: "4px" }} />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <DropzoneComponent onDrop={onDrop} />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing="auto" mb="34px">
                <Grid item xs={12}>
                  <Box sx={{ mt: "70px" }}>
                    <Typography
                      sx={{
                        ml: "26px",

                        whiteSpace: "pre-line",
                        color: "blue.main",
                      }}
                      variant="paragraphMono"
                    >
                      {userType === "pro" || userType === "admin"
                        ? "Project Images"
                        : "Please upload up to five images"}
                    </Typography>
                    {userType === "user" && (
                      <Box mt="25px" ml="26px" width="324px">
                        <ActionButton
                          onClick={() => setUploadingPhotos(true)}
                          title="Upload Project Photos"
                        />
                      </Box>
                    )}
                  </Box>
                </Grid>

                {appointmentImages.map((url, index) => {
                  return (
                    <Grid key={index} item xs="auto" ml="26px" mt="46px">
                      <Box
                        item
                        xs={12}
                        md={3}
                        height="200px"
                        width="200px"
                        maxHeight="280px"
                        onClick={() => openImageViewer(index)}
                        sx={{
                          backgroundImage: `url(${url})`,
                          backgroundSize: "cover",
                        }}
                      >
                        {userType === "user" && (
                          <IconButton
                            onClick={(event) => {
                              deletePhoto(url);
                              event.stopPropagation();
                            }}
                            sx={{
                              position: "relative",
                              zIndex: 2,
                              borderRadius: "0",
                              "&:hover": { backgroundColor: "transparent" },
                            }}
                          >
                            <DeleteIcon
                              fontSize="large"
                              sx={{ color: "blue.main", mt: "5px", ml: "4px" }}
                            />
                          </IconButton>
                        )}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Grid>
          <Grid
            container
            xs={12}
            rowSpacing={3}
            sx={{
              mt: "25px",
              pt: "21px",
              pr: isPortrait ? "20px" : "44px",
              backgroundColor: "extraLightOlive.main",
            }}
          >
            <Grid
              item
              pl={isPortrait ? "20px" : "44px"}
              mb="15px"
              sm={12}
              md={12}
              lg={4}
            >
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "blue.main",
                }}
                variant="paragraphMono"
              >
                What project or topic are you looking to discuss?
              </Typography>
            </Grid>
            <Grid xs={12} lg={8} pl={isPortrait ? "20px" : "44px"}>
              {userType === "user" ? (
                <Input
                  type="text"
                  name="question1"
                  multiline
                  rows={6}
                  onChange={(e) => {
                    setSaved(false);
                    setQuestion1(e.target.value);
                  }}
                  value={question1}
                  disableUnderline
                  fullWidth
                  placeholder=""
                  sx={{
                    ...commonStyles,
                    backgroundColor: "white",
                  }}
                ></Input>
              ) : (
                <Typography
                  sx={{
                    whiteSpace: "pre-line",
                    color: "blue.main",
                    mr: "5px",
                  }}
                  variant="paragraphMono"
                >
                  {question1}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              pl={isPortrait ? "20px" : "44px"}
              mb="15px"
              sm={12}
              md={12}
              lg={4}
            >
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "blue.main",
                }}
                variant="paragraphMono"
              >
                What are your goals for your session?
              </Typography>
            </Grid>
            <Grid xs={12} lg={8} pl={isPortrait ? "20px" : "44px"}>
              {userType === "user" ? (
                <Input
                  type="text"
                  name="question2"
                  multiline
                  rows={5}
                  onChange={(e) => {
                    setSaved(false);
                    setQuestion2(e.target.value);
                  }}
                  value={question2}
                  disableUnderline
                  fullWidth
                  placeholder=""
                  sx={{
                    ...commonStyles,
                    backgroundColor: "white",
                  }}
                ></Input>
              ) : (
                <Typography
                  sx={{
                    whiteSpace: "pre-line",
                    color: "blue.main",
                    mr: "5px",
                  }}
                  variant="paragraphMono"
                >
                  {question2}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              pl={isPortrait ? "20px" : "44px"}
              mb="15px"
              sm={12}
              md={12}
              lg={4}
            >
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "blue.main",
                }}
                variant="paragraphMono"
              >
                Are there any tools, parts, materials, or obstacles specific to
                your project that you want to share with your Pro in advance of
                the session? If so, please do so here.
              </Typography>
            </Grid>
            <Grid xs={12} lg={8} pl={isPortrait ? "20px" : "44px"}>
              {userType === "user" ? (
                <Input
                  type="text"
                  name="question3"
                  multiline
                  rows={5}
                  onChange={(e) => {
                    setSaved(false);
                    setQuestion3(e.target.value);
                  }}
                  value={question3}
                  disableUnderline
                  fullWidth
                  placeholder=""
                  sx={{
                    ...commonStyles,
                    backgroundColor: "white",
                  }}
                ></Input>
              ) : (
                <Typography
                  sx={{
                    whiteSpace: "pre-line",
                    color: "blue.main",
                    mr: "5px",
                  }}
                  variant="paragraphMono"
                >
                  {question3}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              pl={isPortrait ? "20px" : "44px"}
              mb="15px"
              sm={12}
              md={12}
              lg={4}
            >
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "blue.main",
                }}
                variant="paragraphMono"
              >
                {`On a scale of 1 to 5 how would you rate your experience level? (For example, 1: I want to hang shelves but I’ve never used a drill; 5: I want to do a full kitchen remodel and I’ve just completed a bathroom remodel.)`}
              </Typography>
            </Grid>
            <Grid xs={12} lg={8} pl={isPortrait ? "20px" : "44px"}>
              {userType === "user" ? (
                <Input
                  type="text"
                  name="question4"
                  multiline
                  rows={5}
                  onChange={(e) => {
                    setSaved(false);
                    setQuestion4(e.target.value);
                  }}
                  value={question4}
                  disableUnderline
                  fullWidth
                  placeholder=""
                  sx={{
                    ...commonStyles,
                    backgroundColor: "white",
                  }}
                ></Input>
              ) : (
                <Typography
                  sx={{
                    whiteSpace: "pre-line",
                    color: "blue.main",
                    mr: "5px",
                  }}
                  variant="paragraphMono"
                >
                  {question4}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              pl={isPortrait ? "20px" : "44px"}
              mb="15px"
              sm={12}
              md={12}
              lg={4}
            >
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "blue.main",
                }}
                variant="paragraphMono"
              >
                Do you have any questions you’d like your Pro to be prepared to
                address in your session?
              </Typography>
            </Grid>
            <Grid xs={12} lg={8} pl={isPortrait ? "20px" : "44px"}>
              {userType === "user" ? (
                <Input
                  type="text"
                  name="question5"
                  multiline
                  rows={5}
                  onChange={(e) => {
                    setSaved(false);
                    setQuestion5(e.target.value);
                  }}
                  value={question5}
                  disableUnderline
                  fullWidth
                  placeholder=""
                  sx={{
                    ...commonStyles,
                    backgroundColor: "white",
                  }}
                ></Input>
              ) : (
                <Typography
                  sx={{
                    whiteSpace: "pre-line",
                    color: "blue.main",
                    mr: "5px",
                  }}
                  variant="paragraphMono"
                >
                  {question5}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              pl={isPortrait ? "20px" : "44px"}
              mb="15px"
              sm={12}
              md={12}
              lg={4}
            >
              <Typography
                sx={{
                  whiteSpace: "pre-line",
                  color: "blue.main",
                }}
                variant="paragraphMono"
              >
                Are there any additional notes for your Pro? To share large
                files please list any relevant links for your Pro (Example:
                Dropbox, Google Doc, Pinterest Board.)
              </Typography>
            </Grid>
            <Grid xs={12} lg={8} pl={isPortrait ? "20px" : "44px"}>
              {userType === "user" ? (
                <Input
                  type="text"
                  name="question6"
                  multiline
                  rows={5}
                  onChange={(e) => {
                    setSaved(false);
                    setQuestion6(e.target.value);
                  }}
                  value={question6}
                  disableUnderline
                  fullWidth
                  placeholder=""
                  sx={{
                    ...commonStyles,
                    backgroundColor: "white",
                  }}
                ></Input>
              ) : (
                <Typography
                  sx={{
                    whiteSpace: "pre-line",
                    color: "blue.main",
                    mr: "5px",
                  }}
                  variant="paragraphMono"
                >
                  {question6}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            sx={{
              pl: isPortrait ? "20px" : "31px",
              pr: isPortrait ? "20px" : "44px",
              mb: "44px",
              backgroundColor: "extraLightOlive.main",
            }}
          >
            {userType === "user" &&
              (saved ? (
                <Typography
                  mb="31px"
                  sx={{
                    whiteSpace: "pre-line",
                    color: "blue.main",
                    mr: "5px",
                  }}
                  variant="paragraphMono"
                >
                  Saved
                </Typography>
              ) : (
                <Grid item xs={3} sm={2} mb="31px">
                  {saving ? (
                    <CircularProgress sx={{ color: "blue.main" }} />
                  ) : (
                    <ActionButton title="Save" onClick={updateDetails} />
                  )}
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Stack>

      <Footer />
    </Box>
  );
};

export default ConsultationDetails;
