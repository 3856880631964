import { Box } from '@mui/material';
import { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../../../user-context';
import { useParams } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import ReactPhoneInput from 'react-phone-input-material-ui';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from 'react-responsive';
import SideBar from '../../SideBar';
import Footer from '../../../../SharedComponents/Footer';
import { storage } from '../../../../App';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import DeleteIcon from '@mui/icons-material/Delete';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Close from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import {
  getFirestore,
  doc,
  addDoc,
  getDoc,
  updateDoc,
  collection,
  query,
  getDocs,
  where,
  deleteDoc,
} from 'firebase/firestore';

import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import ArrowWhite from '../../../../Assets/Images/arrow-white.svg';
import Grid from '@mui/material/Unstable_Grid2/';
import { ActionButton, LinkButton } from '../../../../SharedComponents/Buttons';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../../App';
import { DropzoneArea } from 'material-ui-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { TextField } from '@material-ui/core';
import AccountCreate from './AccountCreate';
import DropzoneComponent from '../../../../SharedComponents/Dropzone';
import { createAppointmentTypes } from './AccountCreate';
import FormControlContext from '@mui/material/FormControl/FormControlContext';
const textStyles = {
  color: 'blue.main',
  fontFamily: 'Code Saver',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '17.5px',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      fontFamily: 'Code Saver',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '145%',
      color: 'blue.main',
    },
  },
};

const commonStyles = {
  pl: '4px',
  pt: '4px',
  mb: '12px',
  fontFamily: 'Code Saver',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '145%',
  color: 'blue.main',
  border: 1,
  borderColor: 'lightOlive.main',
};

const EditPro = (props) => {
  const db = getFirestore();
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const [uploadingPhotos, setUploadingPhotos] = useState(false);
  const [uploadingHeadshot, setUploadingHeadshot] = useState(false);
  const [pro, setPro] = useState();
  const [proImages, setProImages] = useState([]);
  const [services, setServices] = useState([]);
  const [trades, setTrades] = useState([]);
  const [regions, setRegions] = useState([]);
  const [serviceSelections, setServiceSelections] = useState([]);
  const [tradeSelections, setTradeSelections] = useState([]);
  const [regionSelection, setRegionSelection] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [verified, setVerified] = useState(false);
  const [value, setValue] = useState();
  const [selectedTimezone, setSelectedTimezone] = useState('America/New_York');
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(true);
  const user = useContext(UserContext);
  const [userType, setUserType] = useState('pro');
  const [canView, setCanView] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [sameDayAvailability, setSameDayAvailability] = useState(false);

  const timeZones = [
    'America/St_Johns',
    'America/Puerto_Rico',
    'America/New_York',
    'America/Chicago',
    'America/Denver',
    'America/Los_Angeles',
  ];

  const { id } = useParams();

  const setUserAttributes = async (id) => {
    if (user.loggedIn == true) {
      const proRef = doc(db, `pros/${id}/`);
      const docSnap = await getDoc(proRef);

      if (docSnap.exists() && user.user.uid == id) {
        const proData = docSnap.data();

        if (proData?.verified == true) {
          setUserType('pro');
        } else {
          setUserType('unverifiedPro');
        }
        setCanView(true);
      } else {
        user.user
          .getIdTokenResult()
          .then((idTokenResult) => {
            setUserType(idTokenResult.claims.userType);
            if (idTokenResult.claims.userType === 'admin') {
              setUserType('admin');
              setCanView(true);
            } else {
              setUserType('user');
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    if (user.loggedIn) {
      setUserAttributes(user.user.uid);
    }
  }, [user]);

  const [mode, setMode] = useState('edit');

  const handleTimezoneChange = (e) => {
    setSelectedTimezone(e.target.value);

    const updateTimezone = httpsCallable(functions, 'updateTimezone');

    updateTimezone({
      calendarId: pro.calendarId,

      timeZone: e.target.value,
    })
      .then((result) => {
        updateDoc(doc(db, 'pros', id), {
          timeZone: e.target.value,
        })
          .then((result) => {
            //setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            //setLoading(false);
          });
      })
      .catch((error) => {
        //setLoading(false);
        console.log(error);
      });
  };
  const getPro = async () => {
    const docRef = doc(db, 'pros', id);
    const docSnap = await getDoc(docRef);
    const proImagesRef = collection(db, `pros/${id}/images`);
    const q = query(proImagesRef);
    const querySnapshot = await getDocs(q);
    const imageURLs = [];

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      imageURLs.push(doc.data().imageURL);
    });

    setProImages(imageURLs);

    var services = [];
    docSnap
      .data()
      .proServices?.map((service) => services.push(service.shortName));

    setServiceSelections(services);

    var trades = [];
    docSnap.data().proTrades?.map((trade) => trades.push(trade.displayName));

    setTradeSelections(trades);

    setRegionSelection(docSnap.data().regionSelection);

    if (docSnap.data().timeZone) {
      setSelectedTimezone(docSnap.data().timeZone);
    }
    setValue(docSnap.data().location);
    setVerified(docSnap.data().verified);
    setSameDayAvailability(docSnap.data().sameDayAvailability);
    setPro(docSnap.data());
  };
  const verifyPro = (value) => {
    updateDoc(doc(db, 'pros', id), {
      verified: value,
    })
      .then((result) => {
        if (!pro.calendarId) {
          const createCalendar = httpsCallable(functions, 'createCalendar');

          createCalendar({
            summary: `${pro.firstName}-${pro.lastName}`,

            description: `${pro.firstName}'s calendar`,
          })
            .then((result) => {
              updateDoc(doc(db, 'pros', id), {
                calendarId: result.data.data.id,
              })
                .then((result) => {})
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        }
        setVerified(value);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSelectionOptions = async () => {
    var q = query(collection(db, 'Services'));

    var querySnapshot = await getDocs(q);
    var services = [];
    querySnapshot.forEach((doc) => {
      const service = {
        id: doc.id,
        ...doc.data(),
      };
      services.push(service);
    });
    setServices(services);

    q = query(collection(db, 'Trades'));

    querySnapshot = await getDocs(q);

    var trades = [];
    querySnapshot.forEach((doc) => {
      const trade = {
        id: doc.id,
        ...doc.data(),
      };
      trades.push(trade);
    });
    setTrades(trades);

    q = query(collection(db, 'regions'));

    querySnapshot = await getDocs(q);

    var regions = [];
    querySnapshot.forEach((doc) => {
      const region = {
        id: doc.id,
        ...doc.data(),
      };
      regions.push(region);
    });
    setRegions(regions);
  };

  const handleServicesChange = (event) => {
    setSaved(false);
    const {
      target: { value },
    } = event;
    setServiceSelections(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleTradeChange = (event) => {
    setSaved(false);
    const {
      target: { value },
    } = event;
    setTradeSelections(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleRegionChange = (event) => {
    setSaved(false);
    const {
      target: { value },
    } = event;
    setRegionSelection(value);
  };

  const updatePro = async () => {
    const docRef = doc(db, 'pros', id);
    const docSnap = await updateDoc(docRef, pro);
  };

  useEffect(() => {
    // Update the document title using the browser API
    setImageURL(pro?.headshot);
  }, [pro]);

  const deletePhoto = async (url) => {
    var filtered = proImages.filter(function (el) {
      return el != url;
    });
    setProImages(filtered);
    const q = query(
      collection(db, `pros/${id}/images`),
      where('imageURL', '==', url)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      deleteDoc(doc.ref);
    });
  };

  const handlePhotoUpload = (files) => {
    if (files.length == 0) return;

    files.forEach((file) => {
      const photoId = uuidv4();
      const path = `proImages/${photoId}`;
      const storageRef = ref(storage, path);
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(ref(storage, path))
          .then(function (url) {
            const collectionRef = collection(db, `pros/${id}/images`);

            const docSnap = addDoc(collectionRef, { imageURL: url })
              .then((result) => {
                setProImages([url, ...proImages]);
                setUploadingPhotos(false);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    });
  };

  const handleHeadshotUpload = (files) => {
    if (files.length === 0) return;

    const photoId = uuidv4();
    const path = `proImages/${photoId}`;
    const storageRef = ref(storage, path);

    uploadBytes(storageRef, files[0]).then((snapshot) => {
      console.log('Uploaded a blob or file!');
      getDownloadURL(ref(storage, path))
        .then(function (url) {
          const docRef = doc(db, 'pros', id);

          const docSnap = updateDoc(docRef, { headshot: url })
            .then((result) => {
              setImageURL(url);
              pro.headshot = url;
              setPro(pro);
              setUploadingHeadshot(false);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  const onPhoneChange = (event) => {
    setSaved(false);
    setPhoneNumber(event);
  };

  const onSameDayAvailabilityChange = (event) => {
    setSaved(false);
    setSameDayAvailability(event.target.checked);
  };

  useEffect(() => {
    getSelectionOptions();

    if (id == undefined) {
      setMode('create');
      setPro({});
    } else if (canView) {
      getPro();
    }
  }, [canView]);
  return (
    <Box width="100%" minHeight="766px" height="auto">
      <Stack
        width="100%"
        minHeight="766px"
        direction={isDesktop ? 'row' : undefined}
      >
        <Box width={isDesktop ? '345px' : '100%'}>
          <SideBar type={props.type} />
        </Box>
        {mode == 'create' ? (
          <Box
            width="100%"
            height="100%"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AccountCreate />
          </Box>
        ) : (
          <Box width="100%" height="auto" sx={{ mx: '44px' }}>
            {userType === 'admin' && (
              <Grid container sx={{ mt: '25px' }}>
                <Grid item xsOffset={10} xs={2}>
                  <ActionButton
                    type="verify"
                    onClick={() => verifyPro(verified ? false : true)}
                    title={verified ? 'Unverify' : 'Verify'}
                  />
                </Grid>
              </Grid>
            )}

            <Box width="66%" sx={{ borderBottom: 1, color: 'lightOlive.main' }}>
              {mode === 'edit' ? (
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    color: 'olive.main',
                    mr: '5px',
                    mt: '66px',
                    mb: '8px',
                  }}
                  variant="h3"
                >
                  {pro && pro.firstName + ' '}
                  {pro && pro.lastName}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    whiteSpace: 'pre-line',
                    color: 'olive.main',
                    mr: '5px',
                    mt: '66px',
                    mb: '8px',
                  }}
                  variant="h3"
                >
                  Add a pro
                </Typography>
              )}
            </Box>

            <Typography
              sx={{
                whiteSpace: 'pre-line',
                mt: '20px',

                color: 'lightOlive.main',
              }}
              display="block"
              variant="paragraphSansLarge"
            >
              {'Your public profile'}
            </Typography>

            {uploadingHeadshot ? (
              <Grid container>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <IconButton
                    onClick={(event) => setUploadingHeadshot(false)}
                    sx={{
                      borderRadius: '0',
                      '&:hover': { backgroundColor: 'transparent' },
                    }}
                  >
                    <Typography
                      sx={{
                        whiteSpace: 'pre-line',
                        mt: '6px',

                        color: 'blue.main',
                      }}
                      display="block"
                      variant="paragraphSans"
                    >
                      Cancel
                    </Typography>

                    <Close sx={{ color: 'blue.main', mt: '5px', ml: '4px' }} />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <DropzoneComponent onDrop={handleHeadshotUpload} />
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                pl="26px"
                mt="30px"
                py="24px"
                spacing="auto"
                mb="34px"
                sx={{ backgroundColor: 'extraLightOlive.main' }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      whiteSpace: 'pre-line',
                      mt: '6px',

                      color: 'olive.main',
                    }}
                    display="block"
                    variant="h3"
                  >
                    Primary Image
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      whiteSpace: 'pre-line',
                      my: '6px',

                      color: 'blue.main',
                    }}
                    display="block"
                    variant="paragraphMono"
                  >
                    Please upload up a single image to be used as your user
                    profile and intro photo.
                  </Typography>
                </Grid>

                {pro?.headshot && (
                  <Box
                    item
                    xs={12}
                    md={3}
                    height="200px"
                    width="200px"
                    maxHeight="280px"
                    sx={{
                      backgroundImage: `url(${pro?.headshot})`,
                      backgroundSize: 'cover',
                    }}
                  ></Box>
                )}
                <Grid item xs={12}>
                  <Box sx={{ mt: '30px' }}>
                    <Box mt="15px" width="144px">
                      <ActionButton
                        onClick={() => setUploadingHeadshot(true)}
                        title="Upload"
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
            {uploadingPhotos ? (
              <Grid container>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <IconButton
                    onClick={(event) => setUploadingPhotos(false)}
                    sx={{
                      borderRadius: '0',
                      '&:hover': { backgroundColor: 'transparent' },
                    }}
                  >
                    <Typography
                      sx={{
                        whiteSpace: 'pre-line',
                        mt: '6px',

                        color: 'blue.main',
                      }}
                      display="block"
                      variant="paragraphSans"
                    >
                      Cancel
                    </Typography>

                    <Close sx={{ color: 'blue.main', mt: '5px', ml: '4px' }} />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <DropzoneComponent onDrop={handlePhotoUpload} />
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                pl="26px"
                mt="30px"
                py="24px"
                spacing="auto"
                mb="34px"
                sx={{ backgroundColor: 'extraLightOlive.main' }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      whiteSpace: 'pre-line',
                      mt: '6px',

                      color: 'olive.main',
                    }}
                    display="block"
                    variant="h3"
                  >
                    Additional Images
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      whiteSpace: 'pre-line',
                      mt: '6px',

                      color: 'blue.main',
                    }}
                    display="block"
                    variant="paragraphMono"
                  >
                    Please upload up to 5 additional Images
                  </Typography>
                </Grid>
                {proImages.map((url, index) => {
                  return (
                    <Grid key={index} item mr="20px" xs="auto" mt="14px">
                      <Box
                        item
                        xs={12}
                        md={3}
                        height="200px"
                        width="200px"
                        maxHeight="280px"
                        sx={{
                          backgroundImage: `url(${url})`,
                          backgroundSize: 'cover',
                        }}
                      >
                        <IconButton
                          onClick={(event) => {
                            deletePhoto(url);
                            event.stopPropagation();
                          }}
                          sx={{
                            position: 'relative',
                            zIndex: 2,
                            borderRadius: '0',
                            '&:hover': { backgroundColor: 'transparent' },
                          }}
                        >
                          <DeleteIcon
                            fontSize="large"
                            sx={{
                              color: 'blue.main',
                              mt: '5px',
                              ml: '4px',
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  <Box sx={{ mt: '30px' }}>
                    <Box mt="15px" width="144px">
                      <ActionButton
                        onClick={() => setUploadingPhotos(true)}
                        title="Upload"
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}

            <Box pt="44px">
              {pro && (
                <Formik
                  initialValues={{
                    ...pro,
                  }}
                  validate={(values) => {
                    setSaved(false);

                    const errors = {};
                    if (!values.firstName) {
                      errors.firstName = 'Required';
                    }
                    if (!values.lastName) {
                      errors.lastName = 'Required';
                    }
                    if (!values.email) {
                      errors.email = 'Required';
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = 'Invalid email address';
                    }

                    console.log(errors);

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setSaving(true);
                    const proServices = [];
                    const docRef = doc(db, 'pros', id);
                    serviceSelections.forEach((selection) => {
                      services.forEach((service) => {
                        if (selection === service.shortName) {
                          proServices.push(service);
                        }
                      });
                    });
                    const proTrades = [];

                    tradeSelections.forEach((selection) => {
                      trades.forEach((trade) => {
                        if (selection === trade.displayName) {
                          proTrades.push(trade);
                        }
                      });
                    });
                    values = {
                      ...values,
                      proServices,
                      proTrades,
                      regionSelection,
                    };

                    if (value) {
                      values.location = value;
                    }
                    if (phoneNumber) {
                      values.phoneNumber = phoneNumber;
                    }
                    if (
                      sameDayAvailability === true ||
                      sameDayAvailability === false
                    ) {
                      values.sameDayAvailability = sameDayAvailability;
                    }
                    const docSnap = updateDoc(docRef, values)
                      .then((result) => {
                        setSubmitting(false);
                        setSaving(false);
                        setSaved(true);
                        getPro();
                      })
                      .catch((error) => {
                        console.log(error);
                        setSubmitting(false);
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          my: '3px',
                          color: 'blue.main',
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {'First Name'}
                      </Typography>
                      <Input
                        type="text"
                        name="firstName"
                        disableUnderline
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        fullWidth
                        inputProps={{ style: { ...commonStyles } }}
                        height="40px"
                        placeholder="E.g. Jane"
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      {errors.firstName &&
                        touched.firstName &&
                        errors.firstName}
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          my: '3px',
                          color: 'blue.main',
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {'Last Name'}
                      </Typography>
                      <Input
                        type="text"
                        name="lastName"
                        disableUnderline
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        fullWidth
                        inputProps={{ style: { ...commonStyles } }}
                        height="40px"
                        placeholder="E.g. Doe"
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      {errors.lastName && touched.lastName && errors.lastName}
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          my: '3px',
                          color: 'blue.main',
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {'Email Address'}
                      </Typography>
                      <Input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        disableUnderline
                        fullWidth
                        inputProps={{ style: { ...commonStyles } }}
                        height="40px"
                        placeholder="E.g. janedbadass.com"
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      {errors.email && touched.email && errors.email}
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          my: '3px',
                          color: 'blue.main',
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {'Pronouns'}
                      </Typography>
                      <Input
                        type="text"
                        name="pronouns"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pronouns}
                        disableUnderline
                        fullWidth
                        inputProps={{ style: { ...commonStyles } }}
                        height="40px"
                        placeholder="Share your pronouns(optional)"
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          my: '3px',
                          color: 'blue.main',
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {'Facebook'}
                      </Typography>
                      <Input
                        type="text"
                        name="facebook"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.facebook}
                        disableUnderline
                        fullWidth
                        inputProps={{ style: { ...commonStyles } }}
                        height="40px"
                        placeholder="Facebook(optional)"
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          my: '3px',
                          color: 'blue.main',
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {'Instagram'}
                      </Typography>
                      <Input
                        type="text"
                        name="instagram"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.instagram}
                        disableUnderline
                        fullWidth
                        inputProps={{ style: { ...commonStyles } }}
                        height="40px"
                        placeholder="Instagram(optional)"
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      {errors.instagram &&
                        touched.instagram &&
                        errors.instagram}
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          my: '3px',
                          color: 'blue.main',
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {'TikTok'}
                      </Typography>
                      <Input
                        type="text"
                        name="tikTok"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tikTok}
                        disableUnderline
                        fullWidth
                        inputProps={{ style: { ...commonStyles } }}
                        height="40px"
                        placeholder="TikTok(optional)"
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      {errors.tikTok && touched.tikTok && errors.tikTok}
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          my: '3px',
                          color: 'blue.main',
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {'YouTube'}
                      </Typography>
                      <Input
                        type="text"
                        name="youTube"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.youTube}
                        disableUnderline
                        fullWidth
                        inputProps={{ style: { ...commonStyles } }}
                        height="40px"
                        placeholder="YouTube(optional)"
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      {errors.youTube && touched.youTube && errors.youTube}
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          my: '3px',
                          color: 'blue.main',
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {'Bio'}
                      </Typography>
                      <Input
                        type="text"
                        name="bio"
                        multiline
                        rows={4}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.bio}
                        disableUnderline
                        fullWidth
                        inputProps={{ style: { ...commonStyles } }}
                        height="40px"
                        placeholder=""
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      <Grid container spacing={2} py="15px">
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }}>
                            <Typography
                              sx={{
                                whiteSpace: 'pre-line',
                                my: '3px',
                                color: 'blue.main',
                              }}
                              display="block"
                              variant="paragraphMono"
                            >
                              {'Services'}
                            </Typography>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="services-checkbox"
                              multiple
                              required
                              onChange={handleServicesChange}
                              value={serviceSelections}
                              renderValue={(selected) => {
                                return (
                                  <Typography
                                    sx={{
                                      whiteSpace: 'pre-line',

                                      color: 'blue.main',
                                    }}
                                    display="block"
                                    variant="paragraphMono"
                                  >
                                    {selected.join(', ')}
                                  </Typography>
                                );
                              }}
                              size="small"
                              variant="standard"
                              disableUnderline={true}
                              width="100%"
                              height="31px"
                              sx={{
                                ...commonStyles,
                              }}
                            >
                              {services.map((service) => (
                                <MenuItem
                                  key={service.id}
                                  value={service.shortName}
                                >
                                  <Typography
                                    sx={{
                                      whiteSpace: 'pre-line',
                                      my: '3px',
                                      color: 'blue.main',
                                    }}
                                    display="block"
                                    variant="paragraphMono"
                                  >
                                    {service.shortName}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }}>
                            <Typography
                              sx={{
                                whiteSpace: 'pre-line',
                                my: '3px',
                                color: 'blue.main',
                              }}
                              display="block"
                              variant="paragraphMono"
                            >
                              {'Trades'}
                            </Typography>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="trades-checkbox"
                              multiple
                              required
                              onChange={handleTradeChange}
                              value={tradeSelections}
                              renderValue={(selected) => {
                                return (
                                  <Typography
                                    sx={{
                                      whiteSpace: 'pre-line',
                                      color: 'blue.main',
                                    }}
                                    display="block"
                                    variant="paragraphMono"
                                  >
                                    {selected.join(', ')}
                                  </Typography>
                                );
                              }}
                              size="small"
                              variant="standard"
                              disableFocus
                              disableUnderline
                              width="100%"
                              height="31px"
                              sx={{
                                ...commonStyles,
                              }}
                            >
                              {trades.map((trade) => (
                                <MenuItem
                                  key={trade.id}
                                  value={trade.displayName}
                                >
                                  <Typography
                                    sx={{
                                      whiteSpace: 'pre-line',
                                      my: '3px',
                                      color: 'blue.main',
                                    }}
                                    display="block"
                                    variant="paragraphMono"
                                  >
                                    {trade.displayName}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }}>
                            <Typography
                              sx={{
                                whiteSpace: 'pre-line',
                                mt: '4px',
                                color: 'blue.main',
                              }}
                              display="block"
                              variant="paragraphMono"
                            >
                              {'Region'}
                            </Typography>
                            <Select
                              id="services-checkbox"
                              required
                              onChange={handleRegionChange}
                              value={regionSelection}
                              size="small"
                              variant="standard"
                              disableUnderline={true}
                              width="100%"
                              height="31px"
                              sx={{
                                ...commonStyles,
                              }}
                            >
                              {regions.map((region) => (
                                <MenuItem
                                  key={region.id}
                                  value={region.displayName}
                                >
                                  <Typography
                                    sx={{
                                      whiteSpace: 'pre-line',
                                      my: '3px',
                                      color: 'blue.main',
                                    }}
                                    display="block"
                                    variant="paragraphMono"
                                  >
                                    {region.displayName}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          mt: '20px',

                          color: 'lightOlive.main',
                        }}
                        display="block"
                        variant="paragraphSansLarge"
                      >
                        {
                          'Additional Information (this information will be kept private)'
                        }
                      </Typography>

                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          my: '3px',
                          color: 'blue.main',
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {'Location (City, State)'}
                      </Typography>
                      <Box
                        pb="20px"
                        sx={{
                          fontFamily: 'Code Saver',
                          color: 'blue.main',
                          borderRadius: '0',
                        }}
                      >
                        <GooglePlacesAutocomplete
                          style={{
                            '.css-yk16xz-control': {
                              borderRadius: 0,
                            },
                          }}
                          selectProps={{
                            value,
                            onChange: (e) => {
                              setValue(e);
                              setSaved(false);
                            },
                          }}
                        />
                      </Box>
                      <Grid container>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: '100%' }}>
                            <Typography
                              sx={{
                                whiteSpace: 'pre-line',
                                mt: '4px',
                                color: 'blue.main',
                              }}
                              display="block"
                              variant="paragraphMono"
                            >
                              {'Timezone'}
                            </Typography>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="services-checkbox"
                              onChange={handleTimezoneChange}
                              value={selectedTimezone}
                              size="small"
                              variant="standard"
                              disableUnderline={true}
                              width="100%"
                              height="31px"
                              sx={{
                                ...commonStyles,
                              }}
                            >
                              {timeZones.map((timeZone) => (
                                <MenuItem key={timeZone} value={timeZone}>
                                  <Typography
                                    sx={{
                                      whiteSpace: 'pre-line',
                                      my: '3px',
                                      color: 'blue.main',
                                    }}
                                    display="block"
                                    variant="paragraphMono"
                                  >
                                    {timeZone}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          my: '3px',
                          color: 'blue.main',
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {'Licensed Credentials'}
                      </Typography>
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          my: '3px',
                          color: 'olive.main',
                        }}
                        display="block"
                        variant="paragraphMonoSmall"
                      >
                        {
                          'Do you have insurance or licensed credentials? -- if so, please share, if applicable to your trade. (Please note that Matriarchy Build encourages all Pros to carry their own General Liability insurance.)'
                        }
                      </Typography>
                      <Input
                        type="text"
                        name="credentials"
                        multiline
                        rows={4}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.credentials}
                        disableUnderline
                        fullWidth
                        inputProps={{ style: { ...commonStyles } }}
                        height="40px"
                        placeholder="(optional)"
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          my: '3px',
                          color: 'blue.main',
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {'Years Experience'}
                      </Typography>
                      <Input
                        type="number"
                        name="yearsExperience"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.yearsExperience}
                        disableUnderline
                        fullWidth
                        inputProps={{ style: { ...commonStyles } }}
                        height="40px"
                        placeholder="E. g. 5"
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      {errors.yearsExperience &&
                        touched.yearsExperience &&
                        errors.yearsExperience}
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          my: '3px',
                          color: 'blue.main',
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {'Availability (Hours per week)'}
                      </Typography>
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          my: '3px',
                          color: 'olive.main',
                        }}
                        display="block"
                        variant="paragraphMonoSmall"
                      >
                        {
                          'How much time (on average) will you be available each week for online consultations?'
                        }
                      </Typography>
                      <Input
                        type="number"
                        name="totalAvailability"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.totalAvailability}
                        disableUnderline
                        fullWidth
                        inputProps={{ style: { ...commonStyles } }}
                        height="40px"
                        placeholder="E. g. 20"
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      {errors.totalAvailability &&
                        touched.totalAvailability &&
                        errors.totalAvailability}
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          my: '3px',
                          color: 'blue.main',
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {'Allow same day availability?'}
                      </Typography>
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          my: '3px',
                          color: 'olive.main',
                        }}
                        display="block"
                        variant="paragraphMonoSmall"
                      >
                        {
                          'Enabling this will all you to bypass the 24 hr calendar rule and allow your calendar to reflect availability starting 1 hr from the current time.'
                        }
                      </Typography>
                      <Field
                        type="checkbox"
                        name="sameDayAvailability"
                        onChange={onSameDayAvailabilityChange}
                        checked={sameDayAvailability}
                        size="large"
                        sx={{
                          padding: '9px 0',
                        }}
                        component={Checkbox}
                      />
                      {/* <Checkbox
                        name="sameDayAvailability"
                        onChange={handleChange}
                        checked={values.sameDayAvailability}
                        height="40px"
                        size="large"
                        sx={{
                          padding: '9px 0',
                        }}
                      /> */}
                      {errors.sameDayAvailability &&
                        touched.sameDayAvailability &&
                        errors.sameDayAvailability}
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',

                          color: 'blue.main',
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {'Phone number'}
                      </Typography>
                      <Box
                        height="44px"
                        mb="20px"
                        sx={{
                          border: 1,
                          color: 'lightOlive.main',
                        }}
                      >
                        <ReactPhoneInput
                          value={pro.phoneNumber}
                          defaultCountry={'us'}
                          onChange={onPhoneChange}
                          component={TextField}
                        />
                      </Box>

                      {userType === 'admin' && (
                        <>
                          <Typography
                            sx={{
                              whiteSpace: 'pre-line',
                              mt: '20px',
                              mb: '3px',
                              color: 'blue.main',
                            }}
                            display="block"
                            variant="paragraphMono"
                          >
                            {'Stripe Connect Id'}
                          </Typography>
                          <Input
                            type="text"
                            name="stripeId"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.stripeId}
                            disableUnderline
                            fullWidth
                            inputProps={{ style: { ...commonStyles } }}
                            height="40px"
                            placeholder="Stripe Id (Admin only)"
                            sx={{
                              ...commonStyles,
                            }}
                          ></Input>{' '}
                        </>
                      )}
                      <Typography
                        sx={{
                          whiteSpace: 'pre-line',
                          my: '3px',
                          color: 'blue.main',
                        }}
                        display="block"
                        variant="paragraphMono"
                      >
                        {'Calendar Id (Admin only)'}
                      </Typography>
                      <Input
                        type="text"
                        name="calendarId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={userType !== 'admin'}
                        value={values.calendarId}
                        disableUnderline
                        fullWidth
                        inputProps={{ style: { ...commonStyles } }}
                        height="40px"
                        placeholder=""
                        sx={{
                          ...commonStyles,
                        }}
                      ></Input>
                      <Grid container sx={{ my: '20px' }}>
                        <Grid item xs={3}>
                          <LinkButton
                            linkTo="/my-account/pros"
                            title="Done"
                            reverse={true}
                          ></LinkButton>
                        </Grid>
                        <Grid item xsOffset={6} xs={3} justifyContent="center">
                          {saved ? (
                            <Typography
                              mb="31px"
                              sx={{
                                whiteSpace: 'pre-line',
                                color: 'blue.main',
                                mx: 'auto',
                              }}
                              variant="paragraphMono"
                            >
                              Saved
                            </Typography>
                          ) : (
                            <>
                              {saving ? (
                                <CircularProgress sx={{ color: 'blue.main' }} />
                              ) : (
                                <ActionButton
                                  type="submit"
                                  title={mode === 'edit' ? 'Save' : 'Create'}
                                />
                              )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              )}
            </Box>
          </Box>
        )}
      </Stack>
      <Footer />
    </Box>
  );
};

export default EditPro;
